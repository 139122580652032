<template>
  <div class="tfoms-info">
    <div class="tfoms-info__header d-flex">
      <div class="tfoms-info__header-title" :class="color + '--text'">
        {{ title }}
      </div>
      <div class="tfoms-info__header-status">
        <m-icon
          v-if="diff > 0"
          color="success"
          :icon="'mdi-arrow-up-thin'"
        /><m-icon v-if="diff < 0" color="error" :icon="'mdi-arrow-down-thin'" />
      </div>
    </div>
    <div class="tfoms-info__header-subtitle">{{ subTitle }}</div>
    <div v-if="!disabled" class="tfoms-info__content d-flex">
      <div
        class="tfoms-info__content-value"
        :class="isPercent ? color + '--text' : ''"
      >
        {{ value | numberSplit }}{{ isPercent ? "%" : "" }}
      </div>
      <div class="tfoms-info__content-meta">
        <div
          class="tfoms-info__content-diff"
          :class="{ 'green--text': diff > 0, 'error--text': diff < 0 }"
        >
          {{ diff > 0 ? "+" : "" }}{{ diff }}{{ isPercent ? "%" : "" }}
        </div>
        <div class="tfoms-info__content-date">
          за
          {{
            date > 0
              ? new Date(2024, date - 1).toLocaleString("ru-Ru", {
                  month: "long",
                })
              : ""
          }}
        </div>
      </div>
    </div>
    <div v-else class="tfoms-info__content d-flex disabled">Нет данных</div>
  </div>
</template>
<script>
export default {
  name: "view-tfoms-info",
  props: {
    title: {
      type: String,
      default: "ПРИКРЕПЛЕННОЕ НАСЕЛЕНИЕ",
    },
    subTitle: {
      type: String,
      default: "Человек",
    },
    value: {
      type: Number,
      default: 98235,
    },
    diff: {
      type: Number,
      default: 134,
    },
    date: {
      type: [String, Number],
      default: "октябрь",
    },
    isPercent: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary",
    },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      year: 2024,
      years: [],
    };
  },
  mounted() {
    this.years = [];
    for (let year = 2015; year <= new Date().getFullYear(); year++)
      this.years.push(year);
  },
};
</script>
<style lang="scss">
.tfoms-info {
  padding: 20px;
  background-color: var(--v-white-base);
  border-radius: 10px;

  .disabled {
    color: var(--v-grey-lighten1);
    font-size: 16px;
    padding: 22px 0;
  }

  &__header {
    &-title {
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }
    &-subtitle {
      font-size: 12px;

      color: var(--v-blue-grey-base);
    }
    &-status {
      height: 16px;
      i {
        margin-top: -4px;
      }
    }
  }

  &__content {
    &-value {
      font-size: 36px;
      color: var(--v-blue-grey-base);
      margin-right: 15px;
    }
    &-diff {
      font-size: 16px;

      font-weight: 600;
    }
    &-date {
      font-size: 12px;
      color: var(--v-blue-grey-base);
      font-weight: 600;
    }
    &-meta {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}
</style>
